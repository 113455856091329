angular.module('app').factory('FieldShiftAssignment', [
  '$resource',
  function ($resource) {
    return $resource(
      '/dispatching/field_shift_assignments.json',
      {},
      {
        dispatching: {
          method: 'GET',
          isArray: true,
        },
      },
    );
  },
]);
